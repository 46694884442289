<template>
  <div class="page">
    <div class="board"></div> <!-- 右下角的半圆 -->
    <div class="header">
      <img
        :src="info.logo"
        class="logo"
        style="cursor: pointer"
        @click="$router.push({ path: '/overview' })"
      />
      <a-button
        class="btn"
        type="primary"
        @click="$router.push({ path: '/overview' })"
      >返回后台</a-button>
    </div>
    <a-row>
      <a-col
        :lg="10"
        :span="24"
      >
        <div class="left">
          <div class="ring-box"></div>
          <div style="height: calc(100vh - 63px); position: absolute; top: 0; width: 100%">
            <img
              class="spot1"
              src="@/assets/images/spot1.png"
            />
            <img
              class="spot2"
              src="@/assets/images/spot2.png"
            />
            <img
              class="phone"
              :src="info.phone_img"
            />
          </div>
        </div>
      </a-col>
      <a-col
        :lg="14"
        :span="24"
      >
        <div class="right">
          <img
            :src="info.logo"
            style="width: 280px; height: 75px; margin-left: -2px"
          />
          <div class="appName">{{ info.app_name }}</div>
          <div class="appIntroduce">
            {{ info.app_desc }}
          </div>
          <div
            class="flex"
            style="margin-top: 21px"
          >
            <div>
              <img
                :src="info.apple_download_url"
                style="height: 150px"
              />
              <div
                style="margin-top: 22px"
                class="flex justify-center"
              >
                <img
                  src="@/assets/images/ios.svg"
                  style="width: 18px; height: 24px; margin-right: 10px; margin-top: 4px"
                />
                <div style="text-align: center">
                  <div class="os-name">苹果下载</div>
                  <div class="os-name1">APP STORE</div>
                </div>
              </div>
            </div>
            <div style="margin-left: 80px">
              <img
                :src="info.android_download_url"
                style="height: 150px"
              />
              <div
                style="margin-top: 22px"
                class="flex justify-center"
              >
                <img
                  src="@/assets/images/android.svg"
                  style="width: 22x; height: 23px; margin-right: 10px; margin-top: 5px"
                />
                <div style="text-align: center">
                  <div class="os-name">安卓下载</div>
                  <div class="os-name1">ANDROID</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <div
      class="footer-bottom"
      v-if="consoleInfo.userinfo"
    >Copyright &copy; {{ consoleInfo.userinfo.company }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      info: {
        android_download_url: '',
      },
      consoleInfo: {
        userinfo: {
          company: "武汉圣莉斯歌顾问管理有限公司｜数字美业经营后台 V2.6.0",
          name: "童正辉",
          phone: "13296678895",
          qrcode: "https://static.aliyun.meirenji.vip/logo/tzh.jpeg",
        },
        json_data: {
          download_page: {
            android_download_url: "https://attachment.shenglisige.com/apk/android.png",
            app_desc: "智能云店Boss是专为圣莉斯歌连锁平台管理者打造的全新应用，融合了全国门店查询、数据报表分析和高效管理工作台等功能。通过这一强大工具，您可以迅速获取一线工作数据，实现对经营情况的全面深入分析，为构建数字化美业生态系统贡献重要力量,让我们一同迈向美业数字化的崭新时代!",
            app_name: "智能云店BOSS APP",
            apple_download_url: "https://attachment.shenglisige.com/apk/apple.png",
            logo: "https://sandbox.static.meirenji.vip/upload/2023/09/06/bd094362a68b57e7b60f0b84a643393d.svg",
            phone_img: "https://sandbox.static.meirenji.vip/upload/2023/09/06/84720eb69d4210b0af44d3dcf1a27c8f.png",
          }
        }
      },
      loading: true,
    }
  },
  computed: {
  },
  created() {
    this.info = this.consoleInfo.json_data.download_page
  },
}
</script>

<style lang="less" scoped>
img {
  -webkit-user-drag: none;
}
.os-name {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
}
.os-name1 {
  font-size: 10px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  letter-spacing: 2px;
  opacity: 0.25;
  margin-right: -4px;
}
.appName {
  font-size: 24px;
  font-weight: 500;
  color: #1f1f1f;
  margin-top: 42px;
  line-height: 33px;
}
.appIntroduce {
  width: 630px;
  font-size: 15px;
  font-weight: 400;
  color: #848795;
  line-height: 28px;
  margin-top: 19px;
}
.left {
  position: relative;
  height: 100%;
  height: calc(100vh - 63px);
}
.right {
  padding-top: 20%;
  padding-left: 14%;
}
.header {
  height: 63px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 240px;
  position: relative;
  z-index: 99;
  justify-content: space-between;

  .logo {
    width: 157px;
    height: 38px;
  }
}
@media (max-height: 769px) {
  .page .left {
    position: relative;
    height: calc(100vh - 163px);
  }
  .page .right {
    padding-top: 13%;
  }
  .page .phone {
    width: auto;
    height: 691px;
    position: absolute;
    right: 0;
    z-index: 9;
    top: -21px;
  }
  .page .footer-bottom {
    bottom: -11px;
  }
}
.page {
  background: #f3f6ff;
  // background: #252e2d;
  position: relative;
  overflow: hidden;
  min-width: 1300px;
  height: 100%;
  .board {
    width: 344px;
    height: 261px;
    background: #859ce3;
    opacity: 0.18;
    filter: blur(36px);
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 157px 130px 0 0;
  }
}
.page::before {
  content: "";
  position: absolute;
  width: 665px;
  height: 621px;
  // border: 120px solid rgba(133, 156, 227, 0.18);
  border: 120px solid rgba(113, 136, 209, 1);
  border-radius: 50%;
  right: -312px;
  bottom: -312px;
}
.page::after {
  content: "";
  position: absolute;
  width: 266px;
  height: 266px;
  background: linear-gradient(180deg, #35c2ff 0%, #3877df 49%, #4b71e9 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px -15px 36px 0px rgba(76, 253, 183, 0.1);
  opacity: 0.1;
  filter: blur(20px);
  border-radius: 266px;
  right: 78px;
  top: -38px;
}

.ring-box {
  width: 1563px;
  height: 1563px;
  border-radius: 1563px;
  position: relative;
  // background-image: linear-gradient(300deg, #252e2d 20%, #33caa3);
  background-image: linear-gradient(
    300deg,
    #f3f6ff 20%,
    rgba(51, 202, 163, 0.6)
  );
  filter: blur(1px);
  left: -500px;
  bottom: -230px;
}
.spot1 {
  position: absolute;
  width: 64px;
  height: 64px;
  right: 450px;
  top: 420px;
}
.spot2 {
  position: absolute;
  width: 83px;
  height: 83px;
  right: -38px;
  top: 230px;
}
.phone {
  width: auto;
  height: 691px;
  position: absolute;
  right: 0;
  z-index: 9;
  top: 60px;
}
// .ring-box::after {
//   position: absolute;
//   width: 600px;
//   height: 600px;
//   filter: blur(36px);
//   // background: #f3f6ff;
//   background: #000;
//   content: "";
//   top: 500px;
//   right: -240px;
//   border-radius: 800px;
// }
.ring-box::before {
  width: 1000px;
  height: 1000px;
  background: #f3f6ff;
  // background: #252e2d;
  border-radius: 1000px;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ring {
  width: 501px;
  height: 501px;
  border-radius: 501px;
  border: 120px solid #4b71e9;
  opacity: 0.69;
  position: absolute;
  right: -187px;
  bottom: -260px;
}

.ring::after {
  width: 344px;
  height: 217px;
  background: #859ce3;
  opacity: 0.18;
  filter: blur(36px);
  content: "";
  position: absolute;
  left: -120px;
  top: -98px;
}
.footer-bottom {
  position: relative;
  left: 0;
  right: 0;
  bottom: 30px;
  color: #8d8d95;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
</style>
